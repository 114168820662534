<template src="./template.html"></template>

<script>
export default {
  name: "ProductImage",
  props: {
    images: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
      active_image: 0,
      prev_image: 1,
      image_animate: false,
      image_src: ""
    }
  },
  methods: {
    ImageChange(index) {
      if (index != this.active_image) {
        this.prev_image = this.active_image
        this.active_image = index
        setTimeout(() => {
          this.image_animate = false;
          this.image_animate = true
        }, 200)
      }
    },
    ChangeOptionImage(url) {
      this.active_image = -1
      this.image_src = url
    }
  },
}
</script>