<template src="./template.html"></template>

<script>
export default {
  name: "ProductOptions",
  props: {
    value: {
      require: true,
      type: String
    },
    options: {
      require: true,
      type: Array
    },
    option_combine: {
      require: true,
      type: Array
    }
  },
  data() {
    return {}
  },
  methods: {
    ChangeOption(index, val) {
      let combine = this.option_combine.filter(item => item.combine_id == this.value)[0]
      let active_option_id = combine.option_id_group.split(",")
      active_option_id[index] = val
      let id_group = ""
      for (let i = 0; i < active_option_id.length; i++) {
        id_group += active_option_id[i]
        i == active_option_id.length - 1 ? "" : id_group += ","
      }

      if (this.option_combine.filter(item => item.option_id_group == id_group)[0].status != "Y") {
        //這個選項不開放選購 開始由下往上找品項
        // 1,2,3
        let avalible_combine = -1
        id_group = id_group.split(",")
        let count = 1
        for (let i = 0; i < 1; i++) {
          let tmp_group = id_group.slice(0, id_group.length - count)
          let result = this.GetAvalibleCombine(tmp_group)
          if (result.length <= 0) {
            count += 1
            i -= 1
          }
          else {
            avalible_combine = result[0].combine_id
            i = 2
          }
        }

        this.$emit("input", avalible_combine)
      }
      else {
        this.$emit("input", this.option_combine.filter(item => item.option_id_group == id_group)[0].combine_id)
      }

    },
    GetOptionValue(index) {
      let combine = this.option_combine.filter(item => item.combine_id == this.value)[0]
      let active_option_id = combine.option_id_group.split(",")[index]
      return this.options[index].options.filter(item => item.option_id == active_option_id)[0]
    },
    GetAvalibleOption(index) {
      if (index == 0) {
        return this.options[index].options
      }
      else {
        let combine = this.option_combine.filter(item => item.combine_id == this.value)[0]
        let active_option_id = combine.option_id_group.split(",")
        active_option_id = active_option_id.slice(0, index)

        let include_array = this.option_combine.filter(item => {
          let include = true
          active_option_id.forEach(element => {
            item.option_id_group.split(",").indexOf(element) == -1 ? include = false : ""
          });
          return include == true ? item : ""
        })
        include_array = include_array.filter(item => item.status == "Y")
        let allow_ids = []
        include_array.forEach(item => {
          allow_ids.push(item.option_id_group.split(",")[index])
        })
        return this.options[index].options.filter(item => allow_ids.indexOf(item.option_id) != -1)
      }
    },
    GetAvalibleCombine(group) {
      let ids = ""
      for (let i = 0; i < group.length; i++) {
        ids += group[i]
        ids += ","
      }
      let result = this.option_combine.filter(item => item.option_id_group.indexOf(ids) != -1)
      return result
    }
  }
}
</script>