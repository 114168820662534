<template src="./template.html"></template>

<script>
export default {
  name: "ShipInfoDialog",
  props: {
    value: {
      require: true,
      type: Boolean
    }
  },
  data() {
    return {}
  },
  methods: {
    Close() {
      this.$emit("input", false)
    }
  }
}
</script>